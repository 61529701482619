.App {
  background-color: black;
  text-align: center;
  height: 100vh;
  background-attachment: fixed; 
}
h1,h2,h4,h3,h5,p,div,li {
  font-family: 'Dosis', sans-serif;
}
p{
  font-size: 18px;
}
.fontd{
  font-family: 'Dosis', sans-serif;
  font-size: 50px;
  margin:'0px'
}
.fontd:hover {
  color:yellow;
}
.skill-img {
  height:120px;
 
}

.hob-button {  
    padding: 6px;
    margin:10px;
    background-color: black;;
    font-size: 20px;
    color:white;
    width:50%;
    border:none;
    cursor:pointer;
    font-family: 'Dosis', sans-serif;
    border-radius: 5px;
  }
.hob-button:hover {
    transition: all 0.5s ease-in-out 0s;
    box-shadow: 0 0 10px 0 rgba(148, 144, 144, 0.4);
  }
.hob-img{
  max-height:200px;
  width:auto;
  margin:10px;
}

@media only screen and (max-width: 600px) {
  .fontd{  
       font-size: 30px;
  }
  .skill-img {
    height:100px;
}
}

